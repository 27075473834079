// import { useMediaQuery } from "react-responsive";

import { useState } from 'react';

export const IsMobile = () => {
	const breakpoint = 768;
	const win = (typeof window !== "undefined" ? window : null)
	// if (typeof window === undefined) return false
	const [isMobile, setIsMobile] = useState(win?.innerWidth <= breakpoint);

	const handleResize = () => {
		setIsMobile(win?.innerWidth <= breakpoint);
	};

	win?.addEventListener('resize', handleResize);

	return isMobile;
};
