import { graphql, Link } from "gatsby";
import React from "react";
import NewLayout from "../components/new_layout";
import SEO from "../components/seo";
import { MainSection100, MainSection50 } from "../components/Layout/sections";
import Tag from "../components/v2023/UI/Tag/tag";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LinkButton from "../components/v2023/UI/LinkButton/link-button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Stats } from "../components/v2024/stats";
import { SectionTools } from "../components/v2024/section-tools";
import ItemGroup from "../components/v2023/UI/Item/item-group";
import { ResponsiveImage } from "../components/v2024/responsive-image";
import { Tabs } from "../components/v2024/tabs";
import ImageGroup from "../components/v2024/image/image-group";
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk";
import { DemoSteps } from "../components/v2024/demo-steps";
import { Container, Container50 } from "../components/Layout/containers";

const Kyb = ({ data, pageContext }) => {
    console.log("🚀 ~ Kyb ~ data:", data)
    const page = data.page
    const lang = pageContext.langKey

    const tabs = [
        {
            title: page.tab_bastanteo_tab, 
            content: <div className="tabs__content-column">
                <div className="tabs__content-column__50">
                    <h2>{page.tab_bastanteo_items[0].title}</h2>
                    <div className="container__text">
                        <ReactMarkdown children={page.tab_bastanteo_items[0].description} rehypePlugins={[rehypeRaw]} />
                    </div>
                </div>
                <div className="tabs__content-column__50">
                    <GatsbyImage image={getImage(page.tab_bastanteo_items[0].image.localFile)} alt={page.tab_bastanteo_items[0].image.alternativeText} />
                </div>
            </div>
        },
        {
            title: page.tab_ia_tab,
            content: <div className="tabs__content-column">
                <div className="tabs__content-column__50">
                    <h2>{page.tab_ia_content[0].title}</h2>
                    <div className="container__text">
                        <ReactMarkdown children={page.tab_ia_content[0].description} rehypePlugins={[rehypeRaw]} />
                    </div>
                </div>
                <div className="tabs__content-column__50">
                <GatsbyImage image={getImage(page.tab_ia_content[0].image.localFile)} alt={page.tab_ia_content[0].image.alternativeText} />
                </div>
            </div>
        }
    ]   

    return (
        <NewLayout pageContext={pageContext} >
            <SEO
                title={page.seo.title}
                description={page.seo.meta_description}
                image={page.seo.image_2}
                lang={pageContext?.langKey}
                translates={pageContext?.translates}
            />
            <div className="main">
                {/* Hero */}
                <MainSection100 sectionType="color" sectionColor="purple-dark">
                    <Container animation="fade-up">
                        {page.header_tag.map(tag => 
                            <Tag tag={{type: 'Default', ...tag}} key={tag.id} />
                        )}
                        <Container text>
                            <h1 className="merriweather">{page.header_title}</h1>
                            <ReactMarkdown children={page.header_description} rehypePlugins={[rehypeRaw]} />
                            <Link to={page.header_button.url} className="button button--ghost button--xl">{page.header_button.text}</Link>
                        </Container>
                    </Container>
                    <Container np animation="fade-up">
                        <DemoSteps demoSteps={page.demo_steps} category="kyb">
                            <ResponsiveImage image1440={page.header_image_1440} image992={page.header_image_992} image768={page.header_image_768} image576={page.header_image_576} />
                        </DemoSteps>
                    </Container>
                    <Container center animation="fade-up">
                        <Stats stats={page.header_key_kpi} />
                    </Container>
                </MainSection100>

                {/* Automated Flows */}
                <SectionTools title={page.business_verification_title} tools={page.business_verification_items} />

                {/* Features */}
                <MainSection100 sectionType="color" sectionColor="gray">
                    <Container>
                        <h2>{page.features_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.features_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <ItemGroup items={page.features_items} hasImage firstOpen unboxed />
                    </Container>
                </MainSection100>

                <MainSection100>
                    <Container>
                        <h2>{page.compliance_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.compliance_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <ResponsiveImage image1440={page.compliance_image_1440}  image992={page.compliance_image_992} image768={page.compliance_image_768} image576={page.compliance_image_576} />
                    </Container>
                </MainSection100>

                <MainSection50 overflowInvert>
                    <Container>
                        <Container50>
                            <Tag tag={{type: 'Info', name: page.module_item_image[0].tag}} />
                            <h2>{page.module_item_image[0].title}</h2>
                            <Container text>
                                <ReactMarkdown children={page.module_item_image[0].description} rehypePlugins={[rehypeRaw]} />
                            </Container>
                            <LinkButton button={page.module_item_image[0].button} className="button button--xl" />
                        </Container50>
                        <Container overflow>
                            <Container overflowImage animation="fade-right">
                                <GatsbyImage
                                    alt={page.module_item_image[0].image.alternativeText}
                                    image={getImage(page.module_item_image[0].image.localFile)} 
                                />
                            </Container>
                        </Container>
                    </Container>
                </MainSection50>

                <MainSection100>
                    <Container>
                        <Tabs small tabs={tabs} />
                    </Container>
                </MainSection100>

                <MainSection100>
                    <Container>
                        <h2>{page.customer_stories_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.customer_stories_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="grid-lg-4">
                            {page.customer_stories_items.map((story , index) => 
                                <ImageGroup basicInfoWithImage={story} animation={{'data-aos': 'zoom-in', 'data-aos-delay': 200 * index }}/>
                            )}
                        </div>
                    </Container>
                </MainSection100>

                {/* Integration */}
                <MainSection100>
                    <Container>
                        <h2>{page.integration_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.integration_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="grid-lg-3">
                            {page.integration_items.map(item => <ImageGroup basicInfoWithImage={item} />)}
                        </div>
                    </Container>
                </MainSection100>

                {/* Ready To Talk */}
                <ReadyToTalk lang={lang} />
            </div>

        </NewLayout>
    )
}

export default Kyb

export const kybQuery = graphql`
    query ($langKey: String) {
        page: strapi2024Kyb (locale: { eq: $langKey }) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            header_tag {
                id
                name
            }
            header_title
            header_description
            header_button {
                text
                url
                blank
            }
            header_image_1440 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1136
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            header_image_992 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 928
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            header_image_768 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 704
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            header_image_576 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 512
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            demo_steps {
                order
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 692
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            header_key_kpi {
                id
                title
                description
            }
            business_verification_title
            business_verification_items {
                id
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            features_title
            features_description
            features_items {
                id
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            compliance_title
            compliance_description
            compliance_image_1440 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1376
                            placeholder: BLURRED
                            formats: [WEBP]
                            breakpoints: [1136]
                        )
                    }
                }
            }
            compliance_image_992 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 928
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            compliance_image_768 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 704
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            compliance_image_576 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 512
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            module_item_image {
                title
                description
                tag
                button {
                    text
                    url
                    blank
                }
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1118
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [928, 860, 704, 512]
                            )
                        }
                    }
                }
            }
            tab_bastanteo_tab
            tab_bastanteo_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            tab_ia_tab
            tab_ia_content {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            customer_stories_title
            customer_stories_description
            customer_stories_items {
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 444
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [314]
                            )
                        }
                    }
                }
            }
            integration_title
            integration_description
            integration_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
`